import { graphql, PageProps } from "gatsby";
import React from "react";

import Layout from "../../components/Layout/layout";
import { SEO } from "../../components/SEO/SEO";

const PrivacyPolicy = ({ data }: PageProps<Queries.PrivacyPolicyQuery>) => {
  return (
    <Layout>
      <div className="docs">
        <h1>POLÍTICA DE PRIVACIDAD</h1>
     <p><strong>INFORMACI&Oacute;N AL USUARIO</strong></p>
<p><strong>D.I.R.E USIETO, S.A.</strong>, en adelante RESPONSABLE, es el Responsable del tratamiento de los datos personales del Usuario y le informa que estos datos ser&aacute;n tratados de conformidad con lo dispuesto en el Reglamento (UE) 2016/679 de 27 de abril de 2016 (GDPR) relativo a la protecci&oacute;n de las personas f&iacute;sicas en lo que respecta al tratamiento de datos personales y a la libre circulaci&oacute;n de estos datos, por lo que se le facilita la siguiente informaci&oacute;n del tratamiento:</p>
<p><strong>Fin del tratamiento:</strong>&nbsp;mantener una relaci&oacute;n comercial con el Usuario. Las operaciones previstas para realizar el tratamiento son:</p>
<ul>
<li>Remisi&oacute;n de comunicaciones comerciales publicitarias por email, fax, SMS, MMS, comunidades sociales o cualquier otro medio electr&oacute;nico o f&iacute;sico, presente o futuro, que posibilite realizar comunicaciones comerciales. Estas comunicaciones ser&aacute;n realizadas por el RESPONSABLE y relacionadas sobre sus productos y servicios, o de sus colaboradores o proveedores con los que &eacute;ste haya alcanzado alg&uacute;n acuerdo de promoci&oacute;n. En este caso, los terceros nunca tendr&aacute;n acceso a los datos personales.</li>
<li>Realizar estudios estad&iacute;sticos.</li>
<li>Tramitar encargos, solicitudes o cualquier tipo de petici&oacute;n que sea realizada por el usuario a trav&eacute;s de cualquiera de las formas de contacto que se ponen a su disposici&oacute;n.</li>
<li>Remitir el bolet&iacute;n de noticias de la p&aacute;gina web.</li>
</ul>
<p><strong>Criterios de conservaci&oacute;n de los datos:</strong>&nbsp;se conservar&aacute;n mientras exista un inter&eacute;s mutuo para mantener el fin del tratamiento y cuando ya no sea necesario para tal fin, se suprimir&aacute;n con medidas de seguridad adecuadas para garantizar la seudonimizaci&oacute;n de los datos o la destrucci&oacute;n total de los mismos.</p>
<p><strong>Comunicaci&oacute;n de los datos:</strong>&nbsp;No se comunicar&aacute;n los datos a terceros, salvo obligaci&oacute;n legal.</p>
<p><strong>Derechos que asisten al Usuario:</strong></p>
<ul>
<li>Derecho a retirar el consentimiento en cualquier momento.</li>
<li>Derecho de acceso, rectificaci&oacute;n, portabilidad y supresi&oacute;n de sus datos y a la limitaci&oacute;n u oposici&oacute;n al su tratamiento.</li>
<li>Derecho a presentar una reclamaci&oacute;n ante la autoridad de control (agpd.es) si considera que el tratamiento no se ajusta a la normativa vigente.</li>
</ul>
<p><strong>Datos de contacto para ejercer sus derechos:</strong></p>
<p>Direcci&oacute;n postal: D.I.R.E USIETO, S.A.. C/ PERTUSA, 16 50197 ZARAGOZA (ZARAGOZA). Email: d.i.r.e.usieto@grupousieto.com</p>
<p><strong>CAR&Aacute;CTER OBLIGATORIO O FACULTATIVO DE LA INFORMACI&Oacute;N FACILITADA POR EL USUARIO</strong></p>
<p>Los Usuarios, mediante la marcaci&oacute;n de las casillas correspondientes y entrada de datos en los campos, marcados con un asterisco (*) en el formulario de contacto o presentados en formularios de descarga, aceptan expresamente y de forma libre e inequ&iacute;voca, que sus datos son necesarios para atender su petici&oacute;n, por parte del prestador, siendo voluntaria la inclusi&oacute;n de datos en los campos restantes. El Usuario garantiza que los datos personales facilitados al RESPONSABLE son veraces y se hace responsable de comunicar cualquier modificaci&oacute;n de los mismos.<br />El RESPONSABLE informa y garantiza expresamente a los usuarios que sus datos personales no ser&aacute;n cedidos en ning&uacute;n caso a terceros, y que siempre que realizara alg&uacute;n tipo de cesi&oacute;n de datos personales, se pedir&aacute; previamente el consentimiento expreso, informado e inequ&iacute;voco por parte los Usuarios. Todos los datos solicitados a trav&eacute;s del sitio web son obligatorios, ya que son necesarios para la prestaci&oacute;n de un servicio &oacute;ptimo al Usuario. En caso de que no sean facilitados todos los datos, no se garantiza que la informaci&oacute;n y servicios facilitados sean completamente ajustados a sus necesidades.</p>
<p><strong>MEDIDAS DE SEGURIDAD</strong></p>
<p>Que de conformidad con lo dispuesto en las normativas vigentes en protecci&oacute;n de datos personales, el RESPONSABLE est&aacute; cumpliendo con todas las disposiciones de las normativas GDPR para el tratamiento de los datos personales de su responsabilidad, y manifiestamente con los principios descritos en el art&iacute;culo 5 del GDPR, por los cuales son tratados de manera l&iacute;cita, leal y transparente en relaci&oacute;n con el interesado y adecuados, pertinentes y limitados a lo necesario en relaci&oacute;n con los fines para los que son tratados.</p>
<p>El RESPONSABLE garantiza que ha implementado pol&iacute;ticas t&eacute;cnicas y organizativas apropiadas para aplicar las medidas de seguridad que establecen el GDPR con el fin de proteger los derechos y libertades de los Usuarios y les ha comunicado la informaci&oacute;n adecuada para que puedan ejercerlos.</p>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;

export const Head = () => (
  <SEO
    title={"Política de pricavidad"}
    robots={"noindex nofollow"}
  />
);

export const query = graphql`
  query PrivacyPolicy {
    sanityDocumentData {
      globals {
        email
        phone
      }
      foodService {
        phone
        email
      }
      logistics {
        email
        email
      }
      legalName
      urlSite
    }
  }
`;
